import React from 'react';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
//import TimelineArea from '../components/timeline';
import loadable from '@loadable/component'
import ProfileCard from '../components/profile-card';

const TimelineArea = loadable(() => import('../components/timeline'))

const AboutTemplate = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;
  const profileImage = getImage(frontmatter.profile_image);
  const secondaryImage = getImage(frontmatter.secondary_image);

  return (
    <Layout title={frontmatter.title} description={frontmatter.description}>
      <ProfileWrapper>
        <ProfileCard image={profileImage} description={html}/>
      </ProfileWrapper>
      {/* <AboutWrapper>
        <AboutImageWrapper image={profileImage} alt="Jonny Smillie" />
        <AboutCopy dangerouslySetInnerHTML={{ __html: html }} />
      </AboutWrapper> */}
      <TimelineArea />
    </Layout>
  );
};

export default AboutTemplate;

const Spacer = styled.div`
  height: 200px
`

const ProfileWrapper = styled.div`
align-items: center;
display: flex;
font-family: 'Open Sans', sans;
justify-content: center;
overflow: hidden;
perspective: 1800px;
text-align: center;
margin: 0 20px;
`

const AboutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5rem;

  @media screen and (min-width: 1000px) {
    margin-top: 5rem;
  }

  @media screen and (max-width: 1000px) {
    & {
      flex-direction: column;
    }

    & > * {
      margin-top: 2rem;
      width: 100%;
      text-align: center;
    }
  }
`;

const AboutImageWrapper = styled(GatsbyImage)`
  display: block;
  border-radius: 50%;
  height: 350px;
  width: 350px;
  backdrop-filter: blur(10px);
  border: 1px solid white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 1);
  @media (max-width: 450px) {
    height: 300px;
    width: 300px;
  }
`;

const AboutCopy = styled.div`
  max-width: 60ch;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 1);

  & p {
    font-size: var(--size-400);
  }
`;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        profile_image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: PNG, height: 400)
          }
        }
      }
    }
  }
`;
