import React, { useRef, useEffect } from 'react';

import j6 from '../images/j6.png';
import mb3 from '../images/mb3.png';

function ProfileCard(props) {
    //const image = getImage(props.image);
    const cardsRef = useRef(null);
    const imagesRef = useRef([]);
    const backgroundsRef = useRef([]);
    const range = 40;
    let timeout;
    const html = props.description;

  const calcValue = (a, b) => (a / b * range - range / 2).toFixed(1);

  useEffect(() => {
    const handleMouseMove = ({ x, y }) => {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      timeout = window.requestAnimationFrame(() => {
        const yValue = calcValue(y, window.innerHeight);
        const xValue = calcValue(x, window.innerWidth);

       if (cardsRef.current)
        {cardsRef.current.style.transform = `rotateX(${yValue}deg) rotateY(${xValue}deg)` }

        imagesRef.current.forEach((image) => {
          image.style.transform = `translateX(${-xValue}px) translateY(${yValue}px)`;
        });

        backgroundsRef.current.forEach((background) => {
          background.style.backgroundPosition = `${xValue * 0.45}px ${-yValue * 0.45}px`;
        });
      });
    };

    // const cards = cardsRef.current;
    // const images = Array.from(imagesRef.current);
    // const backgrounds = Array.from(backgroundsRef.current);

    document.addEventListener('mousemove', handleMouseMove, false);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove, false);
    };
  }, []);

  return (
    <div className="cards" ref={cardsRef}>
      <h3>Hi, I'm Jonny Smillie</h3>
      <h1>Web developer</h1>
      <div className="card card__one">
        <div className="card__bg"></div>
        <img
          className="card__img"
          src={mb3}
          alt=""
          ref={(el) => {
            if (el && !imagesRef.current.includes(el)) {
              imagesRef.current.push(el);
            }
          }}
        />
        <div className="card__text">
          <p className="card__title">This is my cat.</p>
        </div>
      </div>
      <div className="card card__two">
        <div className="card__bg"></div>
        <img
          className="card__img"
          src={j6}
          alt=""
          ref={(el) => {
            if (el && !imagesRef.current.includes(el)) {
              imagesRef.current.push(el);
            }
          }}
        />
        <div className="card__text">
          <p className="card__title">This is me.</p>
        </div>
      </div>
      <div className="card card__three">
        <div className="card__bg"></div>
        <img
          className="card__img"
          src={mb3}
          alt=""
          ref={(el) => {
            if (el && !imagesRef.current.includes(el)) {
              imagesRef.current.push(el);
            }
          }}
        />
        <div className="card__text">
          <p className="card__title">This is my cat again.</p>
        </div>
      </div>
      <div className='profileCardBodyText' dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}

export default ProfileCard;
